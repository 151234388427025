import React from "react";
import "./NotFoundPage.css";

const NotFoundPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="container">
        <h1 className="">404 Страница не найдена</h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
